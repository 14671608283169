// Entry point for the build script in your package.json

import jquery from 'jquery'
globalThis.jQuery = jquery
globalThis.$ = jquery

require("@rails/ujs").start()
// 新しいバージョンの popperjs
// var popperjs = require('@popperjs/core')
// globalThis.Popper = popperjs

// 古いバージョンの popperjs
var popperjs = require('popper.js')
globalThis.Popper = popperjs

