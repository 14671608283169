globalThis.SnsShare = function SnsShare(title, url) {
  this.title = title;
  this.url = url;
}

// 画面に存在するSNSボタンに対してシェアの動作を付与する。
// なお、SNSボタンはclassで判定される。
SnsShare.prototype.attachSnsShareAction = function () {
  var title, url;
  // IEでデフォルト引数が動かないのでif利用。
  if (typeof this.title !== 'undefined') {
    title = this.title;
  } else {
    title = $("title").html();
  }

  if (typeof this.url !== 'undefined') {
    url = this.url;
  } else {
    url = location.href;
  }

  // twitter シェアの生成
  var text = encodeURIComponent(title);
  var tweetUrl = 'https://twitter.com/share?url=' + url + '&text=' + text;
  $('a.js-twitter-share').attr("href", tweetUrl);

  // facebookシェアの生成
  var facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + url;
  $('a.js-facebook-share').attr("href", facebookUrl);

  // LINEシェア
  $('.js-line-share').off('click');
  $('.js-line-share').on('click', function () {
    var message = title + ' ' + url;
    var shareLink = 'https://line.me/R/msg/text/?' + encodeURIComponent(message);
    window.open(shareLink, '_blank')
  })

  // はてなシェア
  var hatenaUrl = 'https://b.hatena.ne.jp/entry/' + url;
  $('a.js-hatena-share').attr("href", hatenaUrl);
  $('a.js-hatena-share').attr('data-hatena-bookmark-title', title)
  $('a.js-hatena-share').attr('data-hatena-bookmark-layout', 'simple')
};
