globalThis.FormCommon = function FormCommon() {}

// 編集中に別画面に移動するときは警告を表示する
FormCommon.prototype.attachNotSavedMessage = function () {
  // 値が変更されたとき(input要素、select要素が変更された場合)に
  // ブラウザの戻るや更新ボタン、タブを閉じるときに警告をだすようにする
  $('input, textarea, select').on('keyup change', function () {
    enableMovingAlert(true);
  })

  // 送信ボタンなどの通常の方法で画面遷移した場合は、
  // 通常通りアラートは表示されずに遷移する
  $('input[type=submit]').on('click', function () {
    enableMovingAlert(false);
  })
}
