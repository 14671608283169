require('../application');
require('bootstrap');

// bootstrap 2重で読み込むとイベントが２重で発生しておかしくなる。
//require('../lib/bootstrap-4-dashboard/bootstrap');

require('../lib/jquery-autocomplete/jquery.autocomplete');

require('./conversations');
require('./form_common');
require('./projects');
require('./sns_share');
require('./util');


// sweetalert をグローバルで使えるようにする。
globalThis.swal = require('sweetalert');

globalThis.autosize = require('autosize');

// 改行をbrタグに変換する
globalThis.nl2br = function nl2br(str) {
  if (str) {
    return str.replace(/[\n\r]/g, "<br />");
  } else {
    return null;
  }
}

// 編集中に移動することを警告するアラートをセットする
globalThis.enableMovingAlert = function enableMovingAlert(enable) {
  if (enable) {
    $(window).on('beforeunload', function () {
      return "入力したデータは保存されていません。本当に移動しますか。";
    });
  } else {
    $(window).off('beforeunload');
  }
}
