// ユーザ同士のメッセージやり取りページで利用するjs
globalThis.Conversations = function Conversations() {
  // API 実行中を判断する
  this.runningFlag = false;
};

// 全てのメッセージを取得する
Conversations.prototype.getMessages = function() {
  let $content = $('#js-content');
  let url = $content.data('get_messages_url');
  let self = this;

  self.showLoading(true);

  $.ajax({
    type: 'GET',
    url: url,
    dataType : 'html'
  })
  // Ajaxリクエストが成功した場合
  .done(function(data){
    $content.html(data);
    self.scrollToUnread();
  })
  // Ajaxリクエストが失敗した場合
  .fail(function(XMLHttpRequest, textStatus, errorThrown){
    console.log(errorThrown);
  })
  .always(function(){
    self.showLoading(false);
  });
}

// 投稿ボタンが押されたときの処理
Conversations.prototype.submitClicked = function() {

  var text = $('#js-message-textarea').val();
  if (text.length <= 0) {
    return;
  }

  if(!this.runningFlag){
    this.runningFlag = true;

    // 送信処理を実行
    this.sendMessages(text);
  }
}

// 入力されたメッセージを送信する
Conversations.prototype.sendMessages = function(message) {
  let $content = $('#js-content');
  let url = $content.data('send_message_url');
  let self = this;

  self.showLoading(true);

  $.ajax({
    type: 'POST',
    url: url,
    data: {'message': message},
    dataType : 'html'
  })
  // Ajaxリクエストが成功した場合
  .done(function(data){
    // 送信成功したらフォームをクリア
    $('#js-message-textarea').val("");
    if(data.length > 0){
      $content.html(data);
    }
  })
  // Ajaxリクエストが失敗した場合
  .fail(function(XMLHttpRequest, textStatus, errorThrown){
    console.log(errorThrown);
  })
  .always(function(){
    self.runningFlag = false;
    self.showLoading(false);
  });
}

// 未読がある場合は、その未読までスクロールする
Conversations.prototype.scrollToUnread = function(){
  if($('.item.unread').length){
    $("html,body").animate({scrollTop:$('.item.unread').offset().top - 30}, 500);
  }else{
    $("html,body").animate({scrollTop:$('.item.js-last').offset().top - 30}, 500);
  }
}

// ローディング画像の表示、非表示を切り替える
Conversations.prototype.showLoading = function(show){
  if(show){
    $('#js-loading-box').show();
  }else{
    $('#js-loading-box').hide();
  }
}
