// 画像投稿フォームでサーバ送信前に画像をプレビュー表示する為のメソッド
// $input : 画像選択イベントが発生したinputオブジェクト
// $img : プレビュー表示する対象のimgオブジェクト
globalThis.showPreviewImage = function showPreviewImage($input, $img){
  if ($input.files && $input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $img.attr('src', e.target.result);
    }
    reader.readAsDataURL($input.files[0]);
  }
}

// YouTube等のタグが見つかったら、横幅にfitできるように追加のタグを挿入する。
globalThis.fitVideoWidth = function fitVideoWidth(){
  var hosts = [
    'www.youtube-nocookie.com/',
    'www.youtube.com/',
    'youtu.be/',
    'youtube.com/'
  ];

  $('.js-video-fixer').each(function(){
    $(this).find('iframe').each(function(){
      // youtubeを判定する
      var src = $(this).attr('src');
      var isVideo = false;
      for(var i = 0; i < hosts.length; i++){
        if(src.indexOf(hosts[i]) >= 0){
          isVideo = true;
          break;
        }
      }

      if(isVideo){
        $(this).wrap('<div class="fit-video" />');
      }
    });
  });
}
