// プロジェクトページで利用するjs
globalThis.Projects = function Projects() {
  // API 実行中を判断する
  this.runningFlag = false;
};

// タブにタップイベントを設定する
Projects.prototype.setTabEvents = function () {
  this.requestContent(index);
}

// 指定したタブを選択状態にする。データの読み込みも実施する。
Projects.prototype.tabSelected = function (index) {
  if(!this.runningFlag){
    this.runningFlag = true;
    this.activateTab(index);
    this.requestContent(index);
    this.scrollToTab();
  }
}

// 指定したタブの中身をAPIで取得して表示する
Projects.prototype.requestContent = function (index) {
  let $content = $('#js-content-body');
  let url = '';
  let parent = this;

  // 場所によってデータ取得先のURLを変える
  if(index == 1){
    url = $content.data('faqs_url');
  }else if(index == 2){
    url = $content.data('reports_url');
  }else{
    url = $content.data('story_url');
  }

  $.ajax({
    type: 'GET',
    url: url,
    dataType : 'html'
  })
  // Ajaxリクエストが成功した場合
  .done(function(data){
    $content.html(data);
    if(index == 0){
      fitVideoWidth();
      // FAQまでスクロールして開くイベントを設置する
      $('#js-open-faq').on('click', function(){
        $('#js-project-tab-1')[0].click();
      });
    }
  })
  // Ajaxリクエストが失敗した場合
  .fail(function(XMLHttpRequest, textStatus, errorThrown){
    console.log(errorThrown);
  })
  .always(function(){
    parent.runningFlag = false;
  });
}

// 指定したタブが選択状態であることを表示
Projects.prototype.activateTab = function (index) {
  let $tab0 = $('#js-project-tab-0');
  let $tab1 = $('#js-project-tab-1');
  let $tab2 = $('#js-project-tab-2');

  $tab0.removeClass('active');
  $tab1.removeClass('active');
  $tab2.removeClass('active');

  if(index == 1){
    $tab1.addClass('active');
  }else if(index == 2){
    $tab2.addClass('active');
  }else{
    $tab0.addClass('active');
  }
}

// タブの位置までスクロールする
Projects.prototype.scrollToTab = function(){
  $("html,body").animate({scrollTop:$('#js-tab').offset().top - 30}, 500);
}
